/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { RepeatWrapping } from "three";
import { BoxGeo } from "../geometry/BoxGeo";
import { PlaneGeo } from "../geometry/PlaneGeo";
import { useTexture } from "@react-three/drei";
import { wood1, plastic, fabric, floor, marble } from "../../data/textures";
import { CylinderGeo } from "../geometry/CylinderGeo";
import { Suspense, useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";

export const PaletteOne = () => {
  const [data, loading] = useFetch(
    "textures/materials/materials.json",
    2,
    2,
    16
  );

  const [wrappedMaterial, setWrappedMaterial] = useState();

  useEffect(() => {
    if (loading) {
      setWrappedMaterial([]);
      console.log("Loading Materials...");
    } else {
      // let material = textureWrap(data.materials);
      console.log(data);
      setWrappedMaterial(data);
      console.log(wrappedMaterial);
      console.log("Materials Loaded");
    }
    console.log("Materials Loaded");
    return () => {
      console.log("Unmouted PaletteOne");
    };
  }, [loading]);

  const wood1Map = useTexture({
    colorMap: wood1.colorMap,
    metalnessMap: wood1.metalnessMap,
    roughnessMap: wood1.roughnessMap,
    normalMap: wood1.normalMap,
    aoMap: wood1.aoMap,
    bumpMap: wood1.bumpMap,
  });

  if (wood1Map.colorMap) {
    wood1Map.colorMap.wrapS = wood1Map.colorMap.wrapT = RepeatWrapping;
    wood1Map.colorMap.repeat.set(2, 2);
    wood1Map.colorMap.anisotropy = 16;
  }
  if (wood1Map.metalnessMap) {
    wood1Map.metalnessMap.wrapS = wood1Map.metalnessMap.wrapT = RepeatWrapping;
    wood1Map.metalnessMap.repeat.set(2, 2);
    wood1Map.metalnessMap.anisotropy = 16;
  }
  if (wood1Map.roughnessMap) {
    wood1Map.roughnessMap.wrapS = wood1Map.roughnessMap.wrapT = RepeatWrapping;
    wood1Map.roughnessMap.repeat.set(2, 2);
    wood1Map.roughnessMap.anisotropy = 16;
  }
  if (wood1Map.normalMap) {
    wood1Map.normalMap.wrapS = wood1Map.normalMap.wrapT = RepeatWrapping;
    wood1Map.normalMap.repeat.set(2, 2);
    wood1Map.normalMap.anisotropy = 16;
  }
  if (wood1Map.aoMap) {
    wood1Map.aoMap.wrapS = wood1Map.aoMap.wrapT = RepeatWrapping;
    wood1Map.aoMap.repeat.set(2, 2);
    wood1Map.aoMap.anisotropy = 16;
  }
  if (wood1Map.colorMap) {
    wood1Map.bumpMap.wrapS = wood1Map.bumpMap.wrapT = RepeatWrapping;
    wood1Map.bumpMap.repeat.set(2, 2);
    wood1Map.bumpMap.anisotropy = 16;
  }

  const floorMap = useTexture({
    colorMap: floor.colorMap,
    metalnessMap: floor.metalnessMap,
    roughnessMap: floor.roughnessMap,
    normalMap: floor.normalMap,
    aoMap: floor.aoMap,
    bumpMap: floor.bumpMap,
  });
  if (floorMap.colorMap) {
    floorMap.colorMap.wrapS = floorMap.colorMap.wrapT = RepeatWrapping;
    floorMap.colorMap.repeat.set(4, 4);
    floorMap.colorMap.anisotropy = 16;
  }
  if (floorMap.metalnessMap) {
    floorMap.metalnessMap.wrapS = floorMap.metalnessMap.wrapT = RepeatWrapping;
    floorMap.metalnessMap.repeat.set(4, 4);
    floorMap.metalnessMap.anisotropy = 16;
  }
  if (floorMap.roughnessMap) {
    floorMap.roughnessMap.wrapS = floorMap.roughnessMap.wrapT = RepeatWrapping;
    floorMap.roughnessMap.repeat.set(4, 4);
    floorMap.roughnessMap.anisotropy = 16;
  }
  if (floorMap.normalMap) {
    floorMap.normalMap.wrapS = floorMap.normalMap.wrapT = RepeatWrapping;
    floorMap.normalMap.repeat.set(4, 4);
    floorMap.normalMap.anisotropy = 16;
  }
  if (floorMap.aoMap) {
    floorMap.aoMap.wrapS = floorMap.aoMap.wrapT = RepeatWrapping;
    floorMap.aoMap.repeat.set(4, 4);
    floorMap.aoMap.anisotropy = 16;
  }
  if (floorMap.colorMap) {
    floorMap.bumpMap.wrapS = floorMap.bumpMap.wrapT = RepeatWrapping;
    floorMap.bumpMap.repeat.set(4, 4);
    floorMap.bumpMap.anisotropy = 16;
  }
  // marble
  const marbleMap = useTexture({
    colorMap: marble.colorMap,
    metalnessMap: marble.metalnessMap,
    roughnessMap: marble.roughnessMap,
    normalMap: marble.normalMap,
    aoMap: marble.aoMap,
    bumpMap: marble.bumpMap,
    // alphaMap: marble.alphaMap,
  });
  if (marbleMap.colorMap) {
    marbleMap.colorMap.wrapS = marbleMap.colorMap.wrapT = RepeatWrapping;
    marbleMap.colorMap.repeat.set(1, 1);
    marbleMap.colorMap.anisotropy = 16;
  }
  if (marbleMap.metalnessMap) {
    marbleMap.metalnessMap.wrapS = marbleMap.metalnessMap.wrapT =
      RepeatWrapping;
    marbleMap.metalnessMap.repeat.set(1, 1);
    marbleMap.metalnessMap.anisotropy = 16;
  }
  if (marbleMap.roughnessMap) {
    marbleMap.roughnessMap.wrapS = marbleMap.roughnessMap.wrapT =
      RepeatWrapping;
    marbleMap.roughnessMap.repeat.set(1, 1);
    marbleMap.roughnessMap.anisotropy = 16;
  }
  if (marbleMap.normalMap) {
    marbleMap.normalMap.wrapS = marbleMap.normalMap.wrapT = RepeatWrapping;
    marbleMap.normalMap.repeat.set(1, 1);
    marbleMap.normalMap.anisotropy = 16;
  }
  if (marbleMap.aoMap) {
    marbleMap.aoMap.wrapS = marbleMap.aoMap.wrapT = RepeatWrapping;
    marbleMap.aoMap.repeat.set(1, 1);
    marbleMap.aoMap.anisotropy = 16;
  }
  if (marbleMap.colorMap) {
    marbleMap.bumpMap.wrapS = marbleMap.bumpMap.wrapT = RepeatWrapping;
    marbleMap.bumpMap.repeat.set(1, 1);
    marbleMap.bumpMap.anisotropy = 16;
  }

  const plasticMap = useTexture({
    colorMap: plastic.colorMap,
    metalnessMap: plastic.metalnessMap,
    roughnessMap: plastic.roughnessMap,
    normalMap: plastic.normalMap,
    aoMap: plastic.aoMap,
    bumpMap: plastic.bumpMap,
  });
  if (plasticMap.colorMap) {
    plasticMap.colorMap.wrapS = plasticMap.colorMap.wrapT = RepeatWrapping;
    plasticMap.colorMap.repeat.set(2, 2);
    plasticMap.colorMap.anisotropy = 16;
  }
  if (plasticMap.metalnessMap) {
    plasticMap.metalnessMap.wrapS = plasticMap.metalnessMap.wrapT =
      RepeatWrapping;
    plasticMap.metalnessMap.repeat.set(2, 2);
    plasticMap.metalnessMap.anisotropy = 16;
  }
  if (plasticMap.roughnessMap) {
    plasticMap.roughnessMap.wrapS = plasticMap.roughnessMap.wrapT =
      RepeatWrapping;
    plasticMap.roughnessMap.repeat.set(2, 2);
    plasticMap.roughnessMap.anisotropy = 16;
  }
  if (plasticMap.normalMap) {
    plasticMap.normalMap.wrapS = plasticMap.normalMap.wrapT = RepeatWrapping;
    plasticMap.normalMap.repeat.set(2, 2);
    plasticMap.normalMap.anisotropy = 16;
  }
  if (plasticMap.aoMap) {
    plasticMap.aoMap.wrapS = plasticMap.aoMap.wrapT = RepeatWrapping;
    plasticMap.aoMap.repeat.set(2, 2);
    plasticMap.aoMap.anisotropy = 16;
  }
  if (plasticMap.colorMap) {
    plasticMap.bumpMap.wrapS = plasticMap.bumpMap.wrapT = RepeatWrapping;
    plasticMap.bumpMap.repeat.set(2, 2);
    plasticMap.bumpMap.anisotropy = 16;
  }
  const fabricMap = useTexture({
    colorMap: fabric.colorMap,
    metalnessMap: fabric.metalnessMap,
    roughnessMap: fabric.roughnessMap,
    normalMap: fabric.normalMap,
    aoMap: fabric.aoMap,
    bumpMap: fabric.bumpMap,
  });
  if (fabricMap.colorMap) {
    fabricMap.colorMap.wrapS = fabricMap.colorMap.wrapT = RepeatWrapping;
    fabricMap.colorMap.repeat.set(1, 1);
    fabricMap.colorMap.anisotropy = 16;
  }
  if (fabricMap.metalnessMap) {
    fabricMap.metalnessMap.wrapS = fabricMap.metalnessMap.wrapT =
      RepeatWrapping;
    fabricMap.metalnessMap.repeat.set(1, 1);
    fabricMap.metalnessMap.anisotropy = 16;
  }
  if (fabricMap.roughnessMap) {
    fabricMap.roughnessMap.wrapS = fabricMap.roughnessMap.wrapT =
      RepeatWrapping;
    fabricMap.roughnessMap.repeat.set(1, 1);
    fabricMap.roughnessMap.anisotropy = 16;
  }
  if (fabricMap.normalMap) {
    fabricMap.normalMap.wrapS = fabricMap.normalMap.wrapT = RepeatWrapping;
    fabricMap.normalMap.repeat.set(1, 1);
    fabricMap.normalMap.anisotropy = 16;
  }
  if (fabricMap.aoMap) {
    fabricMap.aoMap.wrapS = fabricMap.aoMap.wrapT = RepeatWrapping;
    fabricMap.aoMap.repeat.set(1, 1);
    fabricMap.aoMap.anisotropy = 16;
  }
  if (fabricMap.colorMap) {
    fabricMap.bumpMap.wrapS = fabricMap.bumpMap.wrapT = RepeatWrapping;
    fabricMap.bumpMap.repeat.set(1, 1);
    fabricMap.bumpMap.anisotropy = 16;
  }

  return (
    <>
      {/* <BoxGeo
        // onTransformMouseDown={onPointerDown}
        // onTransformMouseUp={onPointerUp}
        // color={"#ffffff"}
        width={4}
        height={4}
        depth={0.125}
        position={[0, 0, -1]}
        transformEnabled={true}
        transformMode={"translate"}
        map={plastic.colorMap}
        metalness={0.5}
        metalnessMap={plastic.roughnessMap}
        roughness={0.5}
        roughnessMap={plastic.roughnessMap}
        normalMap={plastic.normalMap}
        normalScale={[0, 1]}
        bumpMap={plastic.bumpMap}
        bumpScale={1}
        aoMap={plastic.aoMap}
        aoMapIntensity={0.5}
        envMapIntensity={1}
      /> */}

      <Suspense fallback="null">
        <PlaneGeo
          width={60}
          height={60}
          position={[-27.7, 15, -3.95]}
          color={"#9DC7AA"}
          // colorMap={wood1Map.colorMap}
          metalness={0}
          // metalnessMap={wood1Map.roughnessMap}
          roughness={1}
          roughnessMap={wood1Map.roughnessMap}
          // normalMap={wood1Map.normalMap}
          // normalScale={[1, 1]}
          // bumpMap={wood1Map.bumpMap}
          // bumpScale={0.1}
          aoMap={wood1Map.aoMap}
          aoMapIntensity={0.5}
          envMapIntensity={1}
        />
        <PlaneGeo
          width={60}
          height={60}
          position={[25, -15, -4]}
          color={"#ffffff"}
          colorMap={floorMap.colorMap}
          metalness={0}
          // metalnessMap={floorMap.roughnessMap}
          roughness={1}
          roughnessMap={floorMap.roughnessMap}
          // normalMap={floorMap.normalMap}
          // normalScale={[1, 1]}
          // bumpMap={floorMap.bumpMap}
          // bumpScale={0.1}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.1}
          aoMap={floorMap.aoMap}
          aoMapIntensity={1}
          envMapIntensity={1}
        />
        <BoxGeo
          transformEnabled={true}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          width={4}
          height={4}
          depth={0.125}
          position={[0, 0, -1.5]}
          color={"#ffffff"}
          colorMap={wood1Map.colorMap}
          metalness={0.5}
          // metalnessMap={wood1Map.roughnessMap}
          roughness={0.5}
          roughnessMap={wood1Map.roughnessMap}
          // normalMap={wood1Map.normalMap}
          // normalScale={[0, 1]}
          bumpMap={wood1Map.bumpMap}
          bumpScale={0.2}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={wood1Map.aoMap}
          aoMapIntensity={0.5}
          envMapIntensity={1}
        />
        <BoxGeo
          transformEnabled={false}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          width={2}
          height={2}
          depth={0.05}
          position={[-1, 1.5, -1.25]}
          color={"#ffffff"}
          colorMap={marbleMap.colorMap}
          metalness={0}
          // metalnessMap={wood1Map.roughnessMap}
          roughness={1}
          roughnessMap={marbleMap.roughnessMap}
          // normalMap={marbleMap.normalMap}
          // normalScale={[1, 1]}
          bumpMap={marbleMap.bumpMap}
          bumpScale={1}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={marbleMap.aoMap}
          aoMapIntensity={1}
          alphaMap={marbleMap.alphaMap}
          envMapIntensity={1}
        />
        <BoxGeo
          transformEnabled={true}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          width={1}
          height={8}
          depth={0.125}
          position={[-2, 0, -2]}
          // color={"#ffffff"}
          colorMap={wood1Map.colorMap}
          metalness={0.125}
          // metalnessMap={wood1Map.roughnessMap}
          roughness={0.5}
          roughnessMap={wood1Map.roughnessMap}
          // normalMap={wood1Map.normalMap}
          // normalScale={[0, 1]}
          bumpMap={wood1Map.bumpMap}
          bumpScale={0.5}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={wood1Map.aoMap}
          aoMapIntensity={1}
          envMapIntensity={1}
        />
        <BoxGeo
          transformEnabled={true}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          width={1}
          height={8}
          depth={0.125}
          position={[-0.75, 0, -2]}
          // color={"#ffffff"}
          colorMap={wood1Map.colorMap}
          metalness={0.5}
          // metalnessMap={wood1Map.roughnessMap}
          roughness={0.5}
          roughnessMap={wood1Map.roughnessMap}
          // normalMap={wood1Map.normalMap}
          // normalScale={[0, 1]}
          bumpMap={wood1Map.bumpMap}
          bumpScale={0.2}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={wood1Map.aoMap}
          aoMapIntensity={0.5}
          envMapIntensity={1}
        />
        <CylinderGeo
          transformEnabled={true}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          radiusTop={0.25}
          radiusBottom={0.25}
          height={3}
          subvision={48}
          position={[-1, 0, -1]}
          // color={"#9442ff"}
          colorMap={fabricMap.colorMap}
          metalness={0}
          // metalnessMap={fabricMap.roughnessMap}
          roughness={1}
          roughnessMap={fabricMap.roughnessMap}
          // normalMap={fabricMap.normalMap}
          // normalScale={[1, 1]}
          bumpMap={fabricMap.bumpMap}
          bumpScale={0.1}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={fabricMap.aoMap}
          aoMapIntensity={1}
          envMapIntensity={1}
        />
        <CylinderGeo
          envMapIntensity={1}
          transformEnabled={true}
          transformMode={"translate"}
          // onTransformMouseDown={onPointerDown}
          // onTransformMouseUp={onPointerUp}
          radiusTop={0.25}
          radiusBottom={0.25}
          height={3}
          subvision={48}
          position={[-2, 0, -1]}
          color={"#23472E"}
          // colorMap={plasticMap.colorMap}
          metalness={0.025}
          // metalnessMap={plasticMap.roughnessMap}
          roughness={0.5}
          roughnessMap={plasticMap.roughnessMap}
          // normalMap={plasticMap.normalMap}
          // normalScale={[0, 1]}
          bumpMap={plasticMap.bumpMap}
          bumpScale={0.025}
          // displacementMap={wood1Map.bumpMap}
          // dispacemntScale={0.2}
          aoMap={plasticMap.aoMap}
          aoMapIntensity={1}
        />
      </Suspense>
    </>
  );
};
