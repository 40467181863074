export const wood1 = {
  colorMap: "/textures/materials/Wood_026_SD/Wood_026_basecolor.jpg",
  metalnessMap: "/textures/materials/Wood_026_SD/Wood_026_roughness.jpg",
  roughnessMap: "/textures/materials/Wood_026_SD/Wood_026_roughness.jpg",
  normalMap: "/textures/materials/Wood_026_SD/Wood_026_normal.jpg",
  aoMap: "/textures/materials/Wood_026_SD/Wood_026_ambientOcclusion.jpg",
  bumpMap: "/textures/materials/Wood_026_SD/Wood_026_height.png",
  displacementMap: "/textures/materials/Wood_026_SD/Wood_026_height.png",
};
export const wood2 = {
  colorMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_basecolor.jpg",
  metalnessMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_roughness.jpg",
  roughnessMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_roughness.jpg",
  normalMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_normal.jpg",
  aoMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_ambientOcclusion.jpg",
  bumpMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_height.png",
  displacementMap: "/textures/materials/Wood049_1K_SD/Wood049_1K_height.png",
};
export const floor = {
  colorMap: "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_Color.jpg",
  metalnessMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_Roughness.jpg",
  roughnessMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_Roughness.jpg",
  normalMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_NormalDX.jpg",
  aoMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_AmbientOcclusion.jpg",
  bumpMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_Displacement.jpg",
  displacementMap:
    "/textures/materials/WoodFloor023_1K-JPG/WoodFloor023_1K_Displacement.jpg",
};
export const marble = {
  colorMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_basecolor.jpg",
  metalnessMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_basecolor.jpg",
  roughnessMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_roughness.jpg",
  normalMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_normal.jpg",
  aoMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_ambientOcclusion.jpg",
  bumpMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_height.png",
  displacementMap:
    "/textures/materials/Marble_White_006_SD/Marble_White_006_height.png",
};
export const leather = {
  colorMap: "/textures/materials/Leather016_1K-JPG/Leather016_1K_Color.jpg",
  metalnessMap:
    "/textures/materials/Leather016_1K-JPG/Leather016_1K_Roughness.jpg",
  roughnessMap:
    "/textures/materials/Leather016_1K-JPG/Leather016_1K_Roughness.jpg",
  normalMap: "/textures/materials/Leather016_1K-JPG/Leather016_1K_NormalGL.jpg",
  aoMap:
    "/textures/materials/Leather016_1K-JPG/Leather016_1K_AmbientOcclusion.jpg",
  bumpMap:
    "/textures/materials/Leather016_1K-JPG/Leather016_1K_Displacement.jpg",
  alphaMap: "/textures/materials/Leather016_1K-JPG/Leather016_1K_Opacity.jpg",
};

export const plastic = {
  colorMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_basecolor.jpg",
  metalnessMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_basecolor.jpg",
  roughnessMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_roughness.jpg",
  normalMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_normal.jpg",
  aoMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_ambientOcclusion.jpg",
  bumpMap:
    "/textures/materials/Plastic_Rough_001_SD/Plastic_Rough_001_height.png",
};
export const metal = {
  colorMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_basecolor.jpg",
  metalnessMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_basecolor.jpg",
  roughnessMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_roughness.jpg",
  normalMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_normal.jpg",
  aoMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_ambientOcclusion.jpg",
  bumpMap: "/textures/materials/Metal035_1K_SD/Metal035_1K_height.png",
};
export const fabric = {
  colorMap: "/textures/materials/Rug_006_SD/Rug_006_basecolor.jpg",
  metalnessMap: "/textures/materials/Rug_006_SD/Rug_006_basecolor.jpg",
  roughnessMap: "/textures/materials/Rug_006_SD/Rug_006_roughness.jpg",
  normalMap: "/textures/materials/Rug_006_SD/Rug_006_normal.jpg",
  aoMap: "/textures/materials/Rug_006_SD/Rug_006_ambientOcclusion.jpg",
  bumpMap: "/textures/materials/Rug_006_SD/Rug_006_height.png",
};
