/* eslint-disable react/prop-types */
import React, { createContext } from "react";
import { useEffect, useRef, useState, Suspense } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  PerspectiveCamera,
  Environment,
  Loader,
  GizmoHelper,
  GizmoViewport,
  useContextBridge,
} from "@react-three/drei";
import "./App.css";
// import { useControls, folder, useCreateStore } from "leva";
import { SphereGeo } from "./components/geometry/SphereGeo";
import {
  VarUI,
  VarColor,
  VarToggle,
  VarSlider,
  VarCategory,
  VarSelect,
} from "react-var-ui";
import "./varui.css";
import { PaletteOne } from "./components/pallets/PaletteOne";
import { useFetch } from "./hooks/useFetch";

const Camera = (props) => {
  const ref = useRef();
  const cameraRef = props.cameraRef;
  return (
    <PerspectiveCamera
      ref={cameraRef || ref}
      fov={50}
      makeDefault
      position={[-3.75, 0, 6]}
      {...props}
    />
  );
};

const Controls = (props) => {
  const { gl, camera } = useThree();
  return (
    <OrbitControls
      ref={props.controlsRef}
      // enablePan={props.pan}
      // enableZoom={props.zoom}
      // enableRotate={props.rotate}
      enableDamping={true}
      target={[-1, 0, 0]}
      maxPolarAngle={Math.PI * 0.5}
      maxAzimuthAngle={Math.PI * 0.5}
      maxDistance={20}
      minDistance={0.3}
      args={[camera, gl.domElement]}
      {...props}
    />
  );
};

const AppContext = createContext({});
function App() {
  const ContextBridge = useContextBridge(AppContext);
  const [panControl, setPanControl] = useState(true);
  const [zoomControl, setZoomControl] = useState(true);
  const [rotateControl, setRoateControl] = useState(true);
  const [envMaps, setEnvMaps] = useState([]);
  const [data, loading] = useFetch("textures/hdr/hdrmaps.json");

  const [values, setValues] = useState({
    background: false,
    envMap: "textures/hdr/Indoor/Studio_Soft_1.hdr",
    color: "#252f28",
    exposure: 1,
    emissiveIntensity: 0,
    metalness: 0.2,
    roughness: 0.3,
    aoMapIntensity: 1,
    bumpScale: 1,
    normalScale: [1, 1],
    clearcoat: 0,
    clearcoatRoughness: 0,
    clearcoatNormalScale: 0,
    ior: 1,
    reflectivity: 0,
    sheen: 0,
    sheenRoughness: 0,
    specularIntensity: 0,
  });
  const cameraRef = useRef();
  const controlsRef = useRef();
  useEffect(() => {
    // const onPointerDown = () => {
    setRoateControl(true);
    setZoomControl(true);
    setPanControl(true);
    console.log(zoomControl, panControl, rotateControl);
    // };
  }, []);
  // const onPointerUp = () => {
  //   setRoateControl(true);
  //   setZoomControl(true);
  //   setPanControl(true);
  //   console.log(zoomControl, panControl, rotateControl);
  // };
  useEffect(() => {
    if (loading) {
      setEnvMaps([]);
      console.log("Loading Enviroment Maps...");
    } else {
      const renameKey = (obj, oldKey, newKey) => {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      };
      let arr = data.hdrMaps;
      arr.forEach((obj) => renameKey(obj, "name", "label"));
      arr.forEach((obj) => renameKey(obj, "link", "value"));
      arr.forEach((obj, index) => (obj.key = index));
      setEnvMaps(arr);
      // console.log(envMaps);
      console.log("Enviroment Maps Loaded");
    }
  }, [loading]);

  return (
    <div
      className="App"
      style={{
        position: "relative",
        zIndex: 0,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="scene">
        <Canvas shadowMap sRGB>
          <ContextBridge>
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
              <GizmoViewport
                axisColors={["red", "green", "blue"]}
                labelColor="black"
              />
            </GizmoHelper>
            <Suspense fallback="null">
              <SphereGeo
                position={[0, 0, 0]}
                color={values.color}
                envMapIntensity={values.exposure}
                emissiveIntensity={values.emissiveIntensity}
                metalness={values.metalness}
                roughness={values.roughness}
                envMap={values.select}
                aoMapIntensity={values.aoMapIntensity}
                normalScale={values.normalScale}
                bumpScale={values.bumpScale}
                clearcoat={values.clearcoat}
                ior={values.ior}
                reflectivity={values.reflectivity}
                sheen={values.sheen}
                sheenRoughness={values.sheenRoughness}
                specularIntensit={values.specularIntensity}
              />
              <PaletteOne />
              <Environment
                background={values.background}
                files={values.envMap}
              />
            </Suspense>
            <Camera cameraRef={cameraRef} />
            <Controls
              controlsRef={controlsRef}
              // zoom={zoomControl}
              // pan={panControl}
              // rotate={rotateControl}
            />
          </ContextBridge>
        </Canvas>
        <Loader />
      </div>
      <div className="panel">
        <VarUI updateValues={setValues} values={values}>
          <VarCategory label="Enviroment">
            <VarToggle path="background" label="Background" />
            <VarSelect path="envMap" label="Enviroment Map" options={envMaps} />
          </VarCategory>

          <VarCategory label="Material">
            <VarColor path="color" label="Color" />
            <VarSlider
              label="Exposure"
              path="exposure"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Emissive"
              path="emissiveIntensity"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Metalness"
              path="metalness"
              min={0}
              max={1}
              step={0.01}
            />

            <VarSlider
              label="Roughness"
              path="roughness"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="AO Intensity"
              path="aoMapIntensity"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Normal Scale"
              path="normalScale"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Bump Scale"
              path="bumpScale"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Clearcoat"
              path="clearcoat"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Clearcoat Roughness Map Scale"
              path="clearcoatRoughness"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Clearcoat Normal Map Scale"
              path=".clearcoatNormalScale"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider label="IOR" path="ior" min={1} max={2.333} step={0.01} />
            <VarSlider
              label="Reflectivity"
              path="reflectivity"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider label="Sheen" path="sheen" min={0} max={1} step={0.1} />
            <VarSlider
              label="Sheen Roughness"
              path="sheenRoughness"
              min={0}
              max={1}
              step={0.01}
            />
            <VarSlider
              label="Specular Intensity "
              path="specularIntensity"
              min={0}
              max={1}
              step={0.01}
            />

            {/* <VarImage label="Texture" path="image" /> */}
          </VarCategory>
        </VarUI>
      </div>
    </div>
  );
}

export default App;
