/* eslint-disable react/prop-types */
export const SphereGeo = ({ children, ...props }) => {
  return (
    <>
      <mesh position={props.position}>
        <sphereGeometry args={[1, 48, 48]} />
        <meshPhysicalMaterial
          color={props.color}
          envMapIntensity={props.envMapIntensity}
          emissive={props.color}
          emissiveIntensity={props.emissiveIntensity}
          metalness={props.metalness}
          roughness={props.roughness}
          aoMap={props.aoMap}
          aoMapIntensity={props.aoMapIntensity}
          normalMap={props.normalMap}
          normalScale={props.normalScale}
          bumpMap={props.bumpMap}
          bumpMapScale={props.bumpMapScale}
          displacementMap={props.displacementMap}
          displacementScale={props.displacementScale}
          clearcoat={props.clearcoat}
          clearcoatMap={props.clearcoatMap}
          clearcoatNormalMap={props.clearcoatNormalMap}
          clearcoatNormalScale={props.clearcoatNormalScale}
          clearcoatRoughnessMap={props.clearcoatRoughnessMap}
          ior={props.ior}
          reflectivity={props.reflectivity}
          sheenColor={props.color}
          sheen={props.sheen}
          sheenColorMap={props.sheenColorMap}
          sheenRoughness={props.sheenRoughness}
          sheenRoughnessMap={props.sheenRoughnessMap}
          specularIntensity={props.specularIntensity}
          specularIntensityMap={props.specularIntensityMap}
        />
        {children}
      </mesh>
    </>
  );
};
