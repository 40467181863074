/* eslint-disable react/prop-types */
import { TransformControls } from "@react-three/drei";
// import { TextureLoader } from "three";

export const CylinderGeo = ({ children, ...props }) => {
  // const { textureUrl } = useContext(props.context);
  // useEffect(() => {
  //   if (textureUrl) {
  //     const textureLoader = new TextureLoader();
  //     textureLoader.load(textureUrl, (t) => {
  //       material.current.map = t;
  //       material.current.needsUpdate = true;
  //     });
  //   }
  //   console.log({ image: textureUrl });
  // }, [textureUrl]);

  return (
    <TransformControls
      mode={props.transformMode}
      enabled={props.transformEnabled}
      onMouseDown={props.onTransformMouseDown}
      onMouseUp={props.onTransformMouseUp}
    >
      <mesh
        position={props.position}
        onPointerDown={props.onPointerDown}
        onPointerUp={props.onPointerUp}
        ref={props.refMesh}
        rotation={[0, 0, 0]}
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[
            props.radiusTop,
            props.radiusBottom,
            props.height,
            props.subvision,
          ]}
        />
        <meshPhysicalMaterial
          ref={props.refMaterial}
          attach="material"
          color={props.color}
          colorMap={props.color}
          envMapIntensity={props.envMapIntensity}
          emissive={props.color}
          map={props.colorMap}
          metalness={props.metalness}
          metalnessMap={props.roughnessMap}
          roughness={props.roughness}
          roughnessMap={props.roughnessMap}
          normalMap={props.normalMap}
          normalScale={props.normalScale}
          bumpMap={props.bumpMap}
          bumpScale={props.bumpScale}
          displacementMap={props.displacementMap}
          displacementScale={props.displacementScale}
          aoMap={props.aoMap}
          aoMapIntensity={props.aoMapIntensity}
          alphaMap={props.alphaMap ? props.alphaMap : null}
          clearcoat={props.clearcoat}
          clearcoatMap={props.clearcoatMap}
          clearcoatNormalMap={props.clearcoatNormalMap}
          clearcoatNormalScale={props.clearcoatNormalScale}
          clearcoatRoughnessMap={props.clearcoatRoughnessMap}
          ior={props.ior}
          reflectivity={props.reflectivity}
          sheenColor={props.color}
          sheen={props.sheen}
          sheenColorMap={props.sheenColorMap}
          sheenRoughness={props.sheenRoughness}
          sheenRoughnessMap={props.sheenRoughnessMap}
          specularIntensity={props.specularIntensity}
          specularIntensityMap={props.specularIntensityMap}
        />
        {children}
      </mesh>
    </TransformControls>
  );
};
